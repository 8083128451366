import React from "react"
import * as S from "./styled"
import Img from "gatsby-image/withIEPolyfill"
import ParallaxContainer from "../Parallax"

const ParallaxImage = ({
  image,
  objectFit = "cover",
  noParallax,
  clickable,
  onImageClick,
  height,
  overlay,
  ...props
}) => (
  <S.Item {...props}>
    <S.ImageContainer clickable={clickable} onClick={onImageClick}>
      <div className="hover-wrapper">
        <ParallaxContainer disabled={noParallax}>
          <Img
            {...image}
            objectFit={objectFit}
            style={{ width: "100%", height }}
          />
        </ParallaxContainer>
      </div>
      {overlay}
    </S.ImageContainer>
    {props.children}
  </S.Item>
)

export default ParallaxImage
