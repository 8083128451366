import styled from "styled-components"

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: initial;
  transition: all 250ms ease-out;
  a {
    color: initial;
    text-decoration: none;
  }
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  > :first-child {
    flex: 1;
    max-width: 170px;
    transition: all 350ms ease-out;
  }
`
