import React from "react"
import * as S from "./styled"
import { ImageCaption } from "../Texts"
import SVG from "react-inlinesvg"
import ParallaxImage from "../ParallaxImage"

const BrandCard = ({
  height,
  objectFit = "cover",
  parallax = true,
  brand: {
    logo,
    seo: { image },
    slug,
    name,
  },
  clickable,
  onBrandClick,
}) => {
  if (1 === 1)
    return (
      <ParallaxImage
        image={image}
        objectFit={objectFit}
        clickable={clickable}
        height={height}
        noParallax={!parallax}
        onImageClick={onBrandClick}
        overlay={
          <S.Overlay className="logo">
            <SVG src={logo.url} />
          </S.Overlay>
        }
      >
        <S.Footer>
          <ImageCaption>{name}</ImageCaption>
        </S.Footer>
      </ParallaxImage>
    )
}

export default BrandCard
