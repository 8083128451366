import React from "react"
import { Parallax } from "react-scroll-parallax"

const ParallaxContainer = ({
  tagOuter = "div",
  scale = 1.05,
  disabled,
  ...props
}) => (
  <Parallax
    tagOuter={tagOuter}
    disabled={disabled}
    className="parallax"
    y={[-20, 30]}
    styleOuter={{ overflow: "hidden" }}
  >
    {props.children}
  </Parallax>
)

export default ParallaxContainer
